.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  flex: 1;
  max-width: 100%;
}

.content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.content ul li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
  color: #555;
}

.content ul li::before {
  content: '';
  background-image: url("../src/assets/img/tick.png");
  color: #7e57c2;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  background-size: 25px;
  background-repeat: no-repeat;}

.bonus-heading {
  margin-top: 1.5rem;
  font-weight: bold;
  color: #333;
}

.image {
  position: absolute;
  right: 0;
  top: 100px;
  max-height: 500px;
  max-width: 400px;
}

.image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  background-color: #fff;
  margin: 0 auto;
}

.bordertop-grey{
  border-top: 1px solid #FBF4FE;
}
.swiper-content-75{
  width: 75% !important;
}