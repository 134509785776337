@media (max-width: 425px){
    .swiper-wrapper{
        height: 340px !important;
    }
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
        bottom: -6px !important;
    }
    .swiper-content h2{
        padding-bottom: 0 !important;
    }
    .swiper-content h3{
        word-break: break-word;
    }
}
@media (max-width: 767px){
    .banner-sec .swiper{
        padding: 5rem 0 2rem 0rem;
    }
    .banner-sec{
        height: auto;
    }
    h3 {
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 30px !important;
        letter-spacing: 0.02em;
        padding-bottom: 20px;
    }
    h2 {
        font-size: 36px !important;
        font-weight: 400 !important;
        line-height: 48px !important;
        letter-spacing: 0.04em;
        padding-bottom: 20px;
    }
    h1 {
        font-size: 40px !important;
        font-weight: 400 !important;
        line-height: 46px !important;
        letter-spacing: 0.02em;
        padding-bottom: 20px;
    }
    .banner-sec .swiper .swiper-slide .swiper-content{
        width: 100%;
        margin-bottom: 2rem;
    }
    .banner-sec .swiper .swiper-slide .swiper-content::before {
        position: absolute;
        content: '';
        width: 8px;
        height: 217px;
        background: linear-gradient(180deg, #952FBA 0%, #F5DCFF 50%, #5A2D6B 100%);
        top: 0;
        left: 20px;
    }
    .design-sec .tab-content{
        width: 100%;
    }
    .contactform-sec .contact-form{
        width: 100%;
    }
    .contactform-sec.content-box{
        width: 100%;
    }
    .design-sec .nav-pills .nav-link{
        width: 100%;
        height: 100%;
        margin: 10px;
    }
    .design-sec .nav-pills .nav-item{
        margin: 15px;
    }
    .desc-center{
        width: 100%;
    }
    .contactform-sec .content-box{
        width: 100%;
    }
    .fluid-border{
        top: 48%; 
      }
    .fluid-border-bottom{
      bottom: 12%; 
    }
    .image{
        position: relative !important;
        top: 0;
    }
    .content{
        flex: auto;
    }
    .content ul li::before{
        font-size: 18px;
    }
    .content ul li{
        display: grid;
        grid-template-columns:10% 90%;
        align-items: baseline;
    }
    .content ul li::before{
        width: 20px;
        height: 20px;
        background-size: 20px;
        position: relative;
        top: 3px;
    }
    .section-wrapper{
        padding: 0px;
    }
}
@media (min-width: 768px) and (max-width: 990px){
    .banner-sec .swiper{
        padding: 0rem 0 5rem 0rem;
    }
    .banner-sec{
        height: auto;
    }
    .banner-sec .swiper .swiper-slide .swiper-content{
        width: 100%;
        margin-bottom: 2rem;
    }
    .banner-sec .swiper .swiper-slide .swiper-content::before{
        left: 20px;
    }
    .design-sec .nav-pills .nav-link{
        width: 165px;
        height: 165px;        
    }
    .design-sec .nav-pills .nav-item{
        margin: 15px;
    }
    .desc-center{
        width: 100%;
    }
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
        bottom: 0 !important;
    }
    .fluid-border{
        top: 34%; 
      }
    .fluid-border-bottom{
      bottom: 13%; 
    }
    .image{
        width: 275px !important;
    }
}
@media (min-width: 991px) and (max-width: 1200px){
    .banner-sec .swiper{
        padding: 5rem 0 2rem 2rem;
    }
    .banner-sec{
        height: auto;
    }
    .banner-sec .swiper .swiper-slide .swiper-content{
        width: 85%;
        margin-bottom: 2rem;
    }
    .fluid-border{
        top: 47%; 
      }
    .fluid-border-bottom{
      bottom: 18%; 
    }
    .design-sec .nav-pills .nav-item{
        margin: 0 10px;
    }
    .design-sec .nav-pills .nav-link{
        width: 145px !important;
        height: 145px !important;
        font-size: 18px;
    }
    .design-sec .nav-pills .nav-link.active{
       font-size: 20px !important;
       width: 160px !important;
       height: 160px !important;
    }
    .content {
        flex: 1;
        max-width: 100% !important;
    }
}
@media (min-width : 1401px){
    /* .design-sec.bg-grey{
        padding: 80px 0 120px 0;
      } */
    
      .design-sec h1{
        font-size: 56px !important;
        width: 75%;
      }
      .aboutus-sec .mt-aboutus{
        margin-top: 150px;
      }
      .industry-textbox{
        width: 75%;
        margin: 0 auto;
        padding-bottom: 48px;
      }
      .why-solution,.industry-sec,.aboutus-sec,.design-sec{
        padding : 60px 0;
      }
      /* .aboutus-sec .about-padding{
        padding: 80px 0;
      } */
}