/* common style */
@import url('https://fonts.googleapis.com/css?family=Poppins');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins' !important;
    /* background-color: #FEFEFE; */
     /* background:linear-gradient(180.21deg, #FEFEFE -0.16%, #F1F1F1 15.46%, #FCF7FF 35.03%, #F7EFFB 56.47%, #FFEDFD 65.62%, #FFECFD 74.08%, #FEFEFE 99.82%); */
}
@font-face {
  font-family: roboto-serif;
  src: url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');
}
.btn-1{
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
    padding: 3px 40px;
    border-radius: 5px;
    background-color: #9010BF;
    color: #fff;
    border: none;
    margin-right: 60px;
    border: 1px solid #9010BF;
}
.btn-1:hover{
  color: #9010BF;
  background: #F5E3FD;
  border: 1px solid #9010BF;
}

.btn-2:hover{
  background: #9010BF;
  color: #fff;
  border: 1px solid #fff;
  box-shadow: none;
}
.btn-2{
    font-size: 20px;
    line-height: 36px;
    font-weight: 600;
    padding: 8px 30px;
    border-radius: 5px;
    color: #9010BF;
    background-color: #fff;
    border: 1px solid #9010BF;
    /* box-shadow: 2px 2px 2px 2px #9010BF; */
}
h1{
    font-size: 64px !important;
    font-weight: 400 !important;
    line-height: 96px !important;
    letter-spacing: 0.02em;
    padding-bottom: 20px;
}
h2{
    font-size: 48px !important;
    font-family: roboto-serif !important;
    font-weight: 400 !important;
    line-height: 60px !important;
    letter-spacing: 0.04em;
    padding-bottom: 20px;
}

h3{
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 48px !important;
    letter-spacing: 0.02em;
    padding-bottom: 20px;
}
section{
    padding-bottom: 60px;
    padding-top: 60px;
}
h6{
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 36px;
    letter-spacing: 0.04em;
}
h5{
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 30px;
    letter-spacing: 0.04em;
    margin-bottom: 15px;
}
p{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.04em;
    margin-bottom: 15px;
}
.list-unstyled{
    list-style: none;
}
.text-violet{
  color: #9010BF;
}
.fs-10{
  font-size: 10px;
  list-style: none;
}
.fs-12{
  font-size: 12px;
}
.para{
  font-size: 18px;
}
/* header */
.navbar-expand-lg .navbar-nav .nav-link{
    font-size: 18px;
    line-height: 36px;
    font-weight: 400;
    margin: 0 20px;
}

.navbar-expand-lg .navbar-nav .nav-link:hover{
  color: #9010BF;
  font-weight: 500;
}

.sticky-top{
  padding-top: 2px;
  background-color: #EABCFF;
}

.nav-link.active{
  color: #9010BF;
  font-weight: 500;
}
/* banner sec */
.banner-sec{
    background-image: url('../img/banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 550px;
}
.about-banner{
   background-image: url('../img/aboutus-img.png');
}
.banner-sec .swiper {
    width: 100%;
    height: 100%;
    padding: 0rem 0 10rem 2rem;
  }
  .banner-sec  .swiper-slide {
    text-align: left;
    font-size: 18px;
    color: #fff;
  }
  .banner-sec  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .banner-sec .swiper .swiper-slide .swiper-content{
    width: 70%;
    margin: 0 auto 0 0;
    position: relative;
  }
  .banner-sec .swiper .swiper-slide .swiper-content::before{
    position: absolute;
    content: '';
    width: 8px;
    height: 100%;
    background: linear-gradient(180deg, #952FBA 0%, #F5DCFF 50%, #5A2D6B 100%);
    top: 0;
  }
  
  .banner-sec .swiper-pagination-bullet{
    border: 2px solid #F5E3FD;
    width: 12px;
    height: 12px;
    background: #D9D9D9;
    opacity: 1;
  }
  .banner-sec .swiper-pagination-bullet-active{
    background: #9010BF;
  }
  /* design sec */
  .design-sec{
    padding: 40px 0;
  }

  .design-sec .nav-pills .nav-link{
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.04em;
    text-align: center;
    background: #FCF6FF;
    box-shadow: 1px 1px 1px 1px #9010BF;
    margin-bottom: 20px;
    border-radius: 20px;
    color: #333;
    width: 160px;
    height: 160px;
    padding: 10px;
  }
  .design-sec .nav-pills .nav-link.active{
    background: #9010BF;
    font-size: 24px;
    width: 200px;
    height: 200px;
  }
  .design-sec .nav-pills .nav-item{
    margin: 0 25px;
  }
  .design-sec  .list-item li{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #9010BF;
    margin-bottom: 10px;
    list-style: none;
  }
  .design-sec .tab-content{
    width: 100%;
    margin: 0 auto;
    min-height: 500px;
  }
  /* footer */
  .footer-sec{
    background-color: #9010BF;
    color: #fff;
    padding: 60px 0;
  }
  .footer-sec ul li{
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 400;
  }
  .contactform-sec input,.contactform-sec textarea{
    background-color: #F5E3FD;
    border: 0.2px solid #9010BF;
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 15px;
    width: 100%;
  }
  .contactform-sec input::placeholder,.contactform-sec textarea::placeholder{
    color: #9010BF;
    font-size: 12px;
  }
  .contactform-sec .contact-form{
    background-color: #fff;
    width: 70%;
    margin: 0 auto;
    padding: 30px;
    border-radius: 20px;
  }
  .contactform-sec .content-box{
    width: 70%;
    margin: 0 auto;
    border-radius: 10px;
  }
  .why-solution,.industry-sec,.aboutus-sec,.design-sec{
    /* background: linear-gradient(180.21deg, #FEFEFE -0.16%, #F1F1F1 15.46%, #FCF7FF 35.03%, #F7EFFB 56.47%, #FFEDFD 65.62%, #FFECFD 74.08%, #FEFEFE 99.82%); */
    background-color: #FBF4FE;
  }
  .td-none{
    text-decoration: none;
  }
  .mb-64{
    margin-bottom: 50px;
  }
  .bg-grey{
    background:#FCF6FF;
  }
  .desc-center{
    width: 80%;
    margin:0 auto;
    text-align: center;
  }
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: 36px !important;
  }
  /* .btn-hover1{
    color:#9010BF;
    background-color: #fff;
    border: 1px solid #9010BF;
  } */

  /* aboutus sec */
  .aboutus-sec .text-content{
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .aboutus-sec h2{
    font-size: 48px !important;
  }
  .aboutus-sec .mt-aboutus{
    margin-top: 20px;
  }
.aboutus-sec .about-padding{
    padding: 40px 0;
  }

  .aboutus-sec .text{
    font-size: 14px;
  }

  /* industry sec */
  .industry-textbox{
    text-align: center;
  }
  #header .active{
    color: #9010BF !important;
    font-weight: bolder !important;
  }
  .fluid-border{
    border: 1px solid #FBF4FE;
    position: relative;
    width: 100%;
    top: 39%; 
  }
.fluid-border-bottom{
  border: 1px solid #FBF4FE;
  position: relative;
  width: 100%;
  bottom: 22%; 
}
.swiper-content h2{
  font-family: 'Poppins' !important;
  font-size: 32px !important;
}
.bg-purple{
  background-color: #F5E3FD;
}
.contact-form input:focus-visible{
  border: 1px solid #952FBA;
  outline: 1px solid #952FBA;
}
.contactform-sec textarea:focus-visible{
  border: 1px solid #952FBA;
  outline: 1px solid #952FBA;
}
.t-12{
  font-size: 12px;
  margin-left: 8px;
}

.footer-sec ul li a{
  cursor: unset;
}